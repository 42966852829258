import ApiBase from '@/core/services/ApiBase';
import type { components, paths } from '@/types';

const getJsonApiData = function <T>(response: { data: { data: T } }) {
  // not JSON API data at the moment
  return response?.data?.data as T;
};

export default class PollService extends ApiBase {
  getLatestPolls() {
    const url: keyof paths = '/v1.0/polls';

    return this.$http
      .get<components['schemas']['ListResponse_QuestionDTOAttr_']>(url)
      .then(getJsonApiData<components['schemas']['QuestionDTOAttr'][]>);
  }

  submitPoll(pollUuid: string, choiceUuid: string) {
    const url = `/v1.0/polls/${pollUuid}/votes`;
    return this.$http.post<components['schemas']['SuccessResponse']>(url, {
      choiceUuid,
    });
  }
}

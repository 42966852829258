<script setup lang="ts">
import { computed, ref, useAttrs } from 'vue';
import type { RouterLinkProps } from 'vue-router';

import SparkIcon from '../SparkIcon';
import { defaultColorScheme } from './colorSchemes';
import type { ColorScheme, DefaultLook, SparkButtonProps } from './types';
import { useSparkButton } from './useSparkButton';

export interface Props extends Partial<SparkButtonProps> {
  look?: DefaultLook | string;
  type?: HTMLButtonElement['type'];
  loading?: boolean;
  icon?: string;
  to?: RouterLinkProps['to'];
  customColorSchemes?: Record<string, ColorScheme>;
  isExternalLink?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  look: 'filled',
  color: 'green',
  shape: 'rounded',
  size: 'md',
  disabled: false,

  type: 'button',
  loading: false,
  icon: undefined,
  to: undefined,
  isExternalLink: false,
  customColorSchemes: () => ({}),
});

const tag = computed(() => {
  if (props.isExternalLink) {
    return 'a';
  } else if (props.to) {
    return 'router-link';
  } else {
    return 'button';
  }
});

const _type = computed(() => {
  return props.to ? undefined : props.type;
});

const _disabled = computed(() => {
  return props.disabled || props.loading;
});

const colorSchemes = { ...defaultColorScheme, ...props.customColorSchemes };

const { buttonCss, iconSize, style } = useSparkButton(
  computed(() => props.look),
  computed(() => props.color),
  computed(() => props.shape),
  computed(() => props.size),
  _disabled,
  colorSchemes,
);

const attributes = computed(() => {
  const attrs: {
    href?: string;
    target?: HTMLAnchorElement['target'];
    to?: RouterLinkProps['to'];
    tabindex?: string;
  } = {};

  if (props.isExternalLink) {
    if (typeof props.to !== 'string') {
      throw new Error(
        'SparkButton: `isExternalLink` requires `to` to be a string',
      );
    }
    attrs.href = props.to;
    attrs.target = '_blank';
    if (_disabled.value) {
      attrs.tabindex = '-1';
    }
  } else if (props.to) {
    attrs.to = props.to;
  }

  return { ...attrs, ...useAttrs() };
});

// because <a> tag does not support disabled attribute
// we add pointer-events-none class to disable the link
const aTagCss = computed(() => {
  return tag.value !== 'button' && _disabled.value ? 'pointer-events-none' : '';
});

const element = ref(null);
defineExpose({
  element,
});
</script>

<template>
  <component
    :is="tag"
    ref="element"
    :type="_type"
    :class="[buttonCss, aTagCss]"
    class="radiate-effect"
    v-bind="attributes"
    :disabled="_disabled ? true : undefined"
    :data-color="color"
    :data-look="look"
    :style="style"
    :data-size="size"
    sp-button-v2
  >
    <SparkIcon
      v-if="props.loading"
      icon="spinner"
      :size="iconSize"
      :spin="props.loading"
    />
    <slot v-if="!loading" name="icon" :icon-size="iconSize">
      <SparkIcon v-if="icon" :icon="icon" :size="iconSize" />
    </slot>
    <slot v-if="shape !== 'circle'" />
  </component>
</template>

<style scoped>
/* fix the issue where antd a[disabled] overrides our styles */
a[disabled][data-look='filled'] {
  &[data-color='green'],
  &[data-color='orange'],
  &[data-color='blue'],
  &[data-color='red'],
  &[data-color='coral-red'],
  &[data-color='gray'],
  &[data-color='black'],
  &[data-color='yellow'],
  &[data-color='neutral'],
  &[data-color='basis'],
  &[data-color='intraday'],
  &[data-color='kpler'] {
    @apply text-white/60;
  }
}

a[disabled][data-look='outlined'],
a[disabled][data-look='text'],
a[disabled][data-look='ghost'] {
  &[data-color='green'] {
    @apply text-green-500/60;
  }
  &[data-color='orange'] {
    @apply text-orange-500/60;
  }
  &[data-color='blue'] {
    @apply text-blue-500/60;
  }
  &[data-color='red'] {
    @apply text-red-500/60;
  }
  &[data-color='coral-red'] {
    @apply text-coral-red-500/60;
  }
  &[data-color='gray'] {
    @apply text-gray-500/60;
  }
  &[data-color='black'] {
    @apply text-black/60;
  }
  &[data-color='yellow'] {
    @apply text-yellow-500/60;
  }
  &[data-color='neutral'] {
    @apply text-neutral-500/60;
  }
  &[data-color='basis'] {
    @apply text-basis/60;
  }
  &[data-color='intraday'] {
    @apply text-white;
  }
  &[data-color='kpler'] {
    @apply text-[rgba(238,91,17,0.6)];
  }
}

.radiate-effect {
  position: relative;
}

.radiate-effect::before {
  content: '';
  position: absolute;
  background-color: transparent;
  border-radius: inherit;
  inset: 0;
  box-shadow: 0 0 2px 2px var(--radiate-color);
  opacity: 0;
  transition:
    opacity 400ms linear,
    box-shadow 400ms cubic-bezier(0.22, 1, 0.36, 1);
}

.radiate-effect:active::before {
  opacity: 0.8;
  box-shadow: 0 0 0 0 var(--radiate-color);
  transition: none;
}
</style>

import { isNil } from 'lodash';

import ApiBase from '@/core/services/ApiBase';
import type { components } from '@/types';
import { throwIfErrors } from '#/utils/exception';

import type {
  AccountSignalTemplatesDTO,
  SignalCurveDTO,
  SignalsReferenceDataDTO,
} from '../pages/spark-signals/types';

export default class SparkSignalsService extends ApiBase {
  public async getReferenceData() {
    const endpoint = 'v1.0/signals/reference-data';

    // override the type as Schema generated type is incorrect
    const response = await this.dedupeGet<{
      errors?: components['schemas']['Error'][];
      data: SignalsReferenceDataDTO;
    }>(endpoint);

    throwIfErrors(response.data);

    return response.data.data;
  }

  public async getTemplates() {
    const endpoint = 'v1.0/signals/templates';
    const response = await this.dedupeGet<{ data: AccountSignalTemplatesDTO }>(
      endpoint,
    );
    return response.data.data;
  }

  public async getTemplate(uuid: string, tsStart: string, tsEnd: string) {
    const path = `v1.0/signals/templates/${uuid}/data`;
    const queryString = `?ts_start=${tsStart}&ts_end=${tsEnd}`;
    const endpoint = `${path}${queryString}`;
    const response = await this.dedupeGet<{ data: SignalCurveDTO[] }>(endpoint);
    return response.data.data;
  }

  public async getRouteSignalData(
    loadingPort: string,
    dischargePort: string,
    via: components['schemas']['PointEnum'] | null,
    month: string | null,
    index: number | null,
    laden: number | null,
    ballast: number | null,
    chartStartDate: string,
    chartEndDate: string,
    resolution: components['schemas']['SignalTSResolutionEnum'] = 'day',
  ) {
    const params = [
      `loading_port=${loadingPort}`,
      `discharge_port=${dischargePort}`,
      `ts_start=${chartStartDate}`,
      `ts_end=${chartEndDate}`,
    ];

    if (!isNil(laden)) {
      params.push(`south_bound=${laden}`);
    }

    if (!isNil(ballast)) {
      params.push(`north_bound=${ballast}`);
    }

    if (month) {
      params.push(`month=${month}`);
    }

    if (!isNil(index)) {
      params.push(`index=${index}`);
    }

    if (via) {
      params.push(`via=${via}`);
    }

    if (resolution) {
      params.push(`resolution=${resolution}`);
    }

    const queryString = `?${params.join('&')}`;
    const endpoint = `v1.0/signals/route${queryString}`;
    const response = await this.dedupeGet<{ data: SignalCurveDTO }>(endpoint);

    return response.data.data;
  }

  public async getNetbackRegionSignalData(
    loadingPort: string,
    via: components['schemas']['PointEnum'] | null,
    region: components['schemas']['RegionEnum'],
    priceFormat: components['schemas']['NetbackSignalFormatEnum'],
    laden: number | null,
    ballast: number | null,
    chartStartDate: string,
    chartEndDate: string,
    month: string | null,
    index: number | null,
    resolution: components['schemas']['SignalTSResolutionEnum'] = 'day',
  ) {
    const params = [
      `loading_port=${loadingPort}`,
      `region=${region}`,
      `price_format=${priceFormat}`,
      `ts_start=${chartStartDate}`,
      `ts_end=${chartEndDate}`,
    ];

    if (via) {
      params.push(`via=${via}`);
    }

    if (!isNil(laden)) {
      params.push(`south_bound=${laden}`);
    } else {
      params.push(`south_bound=0`);
    }

    if (!isNil(ballast)) {
      params.push(`north_bound=${ballast}`);
    } else {
      params.push(`north_bound=0`);
    }

    if (month) {
      params.push(`month=${month}`);
    }

    if (!isNil(index)) {
      params.push(`index=${index}`);
    }

    if (resolution) {
      params.push(`resolution=${resolution}`);
    }

    const queryString = `?${params.join('&')}`;
    const endpoint = `v1.0/signals/netback-region${queryString}`;
    const response = await this.dedupeGet<{ data: SignalCurveDTO }>(endpoint);

    return response.data.data;
  }

  public async getNetbackMaxSignalData(
    loadingPort: string,
    neaVia: components['schemas']['PointEnum'] | null,
    nweVia: components['schemas']['PointEnum'] | null,
    priceFormat: components['schemas']['NetbackSignalFormatEnum'],
    laden: number,
    ballast: number,
    chartStartDate: string,
    chartEndDate: string,
    month: string | null,
    index: number | null,
    resolution: components['schemas']['SignalTSResolutionEnum'] = 'day',
  ) {
    const params = [
      `loading_port=${loadingPort}`,
      `price_format=${priceFormat}`,
      `ts_start=${chartStartDate}`,
      `ts_end=${chartEndDate}`,
    ];

    if (neaVia) {
      params.push(`nea_via=${neaVia}`);
    }

    if (nweVia) {
      params.push(`nwe_via=${nweVia}`);
    }

    if (!isNil(laden)) {
      params.push(`south_bound=${laden}`);
    }

    if (!isNil(ballast)) {
      params.push(`north_bound=${ballast}`);
    }

    if (month) {
      params.push(`month=${month}`);
    }

    if (!isNil(index)) {
      params.push(`index=${index}`);
    }

    if (resolution) {
      params.push(`resolution=${resolution}`);
    }

    const queryString = `?${params.join('&')}`;
    const endpoint = `v1.0/signals/netback-max${queryString}`;
    const response = await this.dedupeGet<{ data: SignalCurveDTO }>(endpoint);

    return response.data.data;
  }

  public async getArbSignalData(
    loadingPort: string,
    neaVia: components['schemas']['PointEnum'] | null,
    nweVia: components['schemas']['PointEnum'] | null,
    laden: number | null,
    ballast: number | null,
    chartStartDate: string,
    chartEndDate: string,
    month: string | null,
    index: number | null,
    resolution: components['schemas']['SignalTSResolutionEnum'] = 'day',
  ) {
    const params = [
      `loading_port=${loadingPort}`,
      `ts_start=${chartStartDate}`,
      `ts_end=${chartEndDate}`,
    ];

    if (neaVia) {
      params.push(`nea_via=${neaVia}`);
    }

    if (nweVia) {
      params.push(`nwe_via=${nweVia}`);
    }

    if (!isNil(laden)) {
      params.push(`south_bound=${laden}`);
    }

    if (!isNil(ballast)) {
      params.push(`north_bound=${ballast}`);
    }

    if (month) {
      params.push(`month=${month}`);
    }

    if (!isNil(index)) {
      params.push(`index=${index}`);
    }

    if (resolution) {
      params.push(`resolution=${resolution}`);
    }

    const queryString = `?${params.join('&')}`;
    const endpoint = `v1.0/signals/arb${queryString}`;
    const response = await this.dedupeGet<{ data: SignalCurveDTO }>(endpoint);

    return response.data.data;
  }

  public async saveNewTemplate(
    payload: components['schemas']['CreateTemplateSignalPayload'],
  ) {
    const endpoint = 'v1.0/signals/templates';
    const response = await this.$http.post<
      components['schemas']['SuccessResponse']
    >(endpoint, payload);

    throwIfErrors(response.data);

    return response.data.data;
  }

  public async saveTemplate(
    uuid: string,
    payload: components['schemas']['UpdateTemplateSignalPayload'],
  ) {
    const endpoint = `v1.0/signals/templates/${uuid}`;
    const response = await this.$http.put<
      components['schemas']['SuccessResponse']
    >(endpoint, payload);

    throwIfErrors(response.data);

    return response.data.data;
  }

  public async deleteTemplate(uuid: string) {
    const endpoint = `v1.0/signals/templates/${uuid}`;
    const response =
      await this.$http.delete<components['schemas']['SuccessResponse']>(
        endpoint,
      );

    throwIfErrors(response.data);

    return response.data.data;
  }

  public async getSignalsData(
    signalQueryParams: components['schemas']['SignalQueryPayload'][],
  ) {
    const endpoint = 'v1.0/signals/data';
    const response = await this.$http.post<
      components['schemas']['ListResponse_SignalQueryResult_']
    >(endpoint, signalQueryParams);

    throwIfErrors(response.data);

    return response.data.data;
  }
}

import ApiBase from '@/core/services/ApiBase';
import type { paths } from '@/types';
import { throwIfErrors } from '#/utils/exception';

export default class GlobalCalcService extends ApiBase {
  async getReferenceData() {
    const path = 'v1.0/global-calculator/reference-data';

    type Response =
      paths['/v1.0/global-calculator/reference-data']['get']['responses']['200']['content']['application/json'];

    const response = await this.$http.get<Response>(path);
    throwIfErrors(response?.data);
    return response?.data;
  }

  async getCalculationByUuid(uuid: string) {
    const path = `v1.0/global-calculator/calculations/${uuid}`;

    type Response =
      paths[`/v1.0/global-calculator/calculations/{uuid}`]['get']['responses']['200']['content']['application/json'];

    const response = await this.$http.get<Response>(path);
    throwIfErrors(response?.data);
    return response?.data;
  }

  async getCalculations() {
    const path = `v1.0/global-calculator/calculations`;

    type Response =
      paths[`/v1.0/global-calculator/calculations`]['get']['responses']['200']['content']['application/json'];

    const response = await this.$http.get<Response>(path);
    throwIfErrors(response?.data);
    return response?.data?.data;
  }

  async saveCalculation(
    uuid: string | null,
    name: string | null,
    parameters: any | null,
  ) {
    const path = `v1.0/global-calculator/calculations`;

    type Response =
      paths[`/v1.0/global-calculator/calculations`]['post']['responses']['201']['content']['application/json'];

    const updatedFields = {} as {
      name?: string;
      parameters?: Record<string, any>;
    };
    if (name) {
      updatedFields['name'] = name;
    }
    if (parameters) {
      updatedFields['parameters'] = parameters;
    }

    const response = await this.$http.post<Response>(path, {
      uuid,
      ...updatedFields,
    });

    throwIfErrors(response?.data);
    return response?.data;
  }

  async saveCalculationAsSnapshot(
    uuid: string,
    name: string,
    parameters: Record<string, any>,
  ) {
    const path = `v1.0/global-calculator/calculations/${uuid}/snapshots`;

    type Response =
      paths[`/v1.0/global-calculator/calculations/{uuid}/snapshots`]['post']['responses']['201']['content']['application/json'];
    const response = await this.$http.post<Response>(path, {
      uuid,
      name,
      parameters,
    });
    throwIfErrors(response?.data);

    return response?.data;
  }

  async deleteCalculation(uuid: string) {
    const path = `v1.0/global-calculator/calculations/${uuid}`;

    type Response =
      paths[`/v1.0/global-calculator/calculations/{uuid}`]['delete']['responses']['200']['content']['application/json'];
    const response = await this.$http.delete<Response>(path);

    return response?.data;
  }
}

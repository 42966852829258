import ApiBase from '@/core/services/ApiBase';
import type { components } from '@/types';

import type { ContractInput, ContractTypeInput } from '../pages/types';

export default class SettlementService extends ApiBase {
  async getSettlementConfig() {
    return await this.$http.get<
      components['schemas']['DetailResponse_GasIndexationConfigDTO_']
    >(`v1.0/gas/indexation/config`);
  }

  async getSettlementData(
    contractId: ContractInput,
    settlementType: ContractTypeInput,
  ) {
    const tickerSymbol = `${contractId}-${settlementType}`;
    return await this.$http.get<
      components['schemas']['ListResponse_GasIndexationDTO_']
    >(`v1.0/gas/indexation/${tickerSymbol}`);
  }
}

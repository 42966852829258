// event.js
import type { Ref } from 'vue';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { analyticsTrack } from '@/core/utils/usageAnalytics';

import { deriveEventName } from './deriveEventName';

export function useClickAnalytics(
  target: Ref<{ element: HTMLElement } | null>,
  opts?: {
    prefixContent?: string;
    postfixContent?: string;
  },
) {
  // if you want, you can also make this
  // support selector strings as target

  const route = useRoute();
  const element = ref<HTMLElement | null | undefined>(null);

  function callback() {
    // get button content
    const buttonText = target?.value?.element?.textContent;

    const content = `${opts?.prefixContent ?? ''} ${buttonText ?? ''} ${
      opts?.postfixContent ?? ''
    }`;

    //skip uuids from url for a clean event name
    const uuidRegex =
      /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/;
    const path = route.path?.replace(uuidRegex, '');

    const eventName = deriveEventName({ content, path });
    analyticsTrack(eventName, {
      properties: {
        label: content.trim(),
        path: route.path,
      },
    });
  }

  onMounted(() => {
    element.value = target?.value?.element;
    if (element.value instanceof HTMLElement) {
      element.value?.addEventListener('click', callback);
    }
  });
  onBeforeUnmount(() => {
    if (element.value instanceof HTMLElement) {
      element.value?.removeEventListener('click', callback);
    }
  });
}

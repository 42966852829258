import dayjs from 'dayjs';
import type { Config, Layout } from 'plotly.js';

import { staticResourceUrl } from '@/config/settings';
import { getFormattedPrice } from '@/core/misc/PriceUtilsV2';
import { DateFormat } from '@/shared/misc/PriceConstantsV2';
import type {
  ArbBreakevenPriceType,
  components,
  PriceTypeEnum,
  SparkPlotData,
  SubContractDataDTO,
  SubContractTypeEnum,
  UnitEnum,
} from '@/types';
import { formatDate, formatToQuarter } from '#/utils/date';

export function applyWatermark(layout: Partial<Layout>) {
  const layoutWithWatermark = {
    // 'Spark Watermark Template'
    // items with a `name` attribute in template.images will be added to any
    // plot using this template
    images: [
      {
        name: 'spark_watermark',
        source: `${staticResourceUrl}/images/logo_b.png`,
        x: 0.5,
        y: 0.5,
        sizex: 1.2,
        sizey: 1.2,
        opacity: 0.04,
        layer: 'below',
        xanchor: 'center',
        yanchor: 'middle',
      },
    ],
  };
  const layoutUsingTemplate = {
    template: { layout: layoutWithWatermark },
  };
  return Object.assign({}, layout, layoutUsingTemplate);
}

export const PriceChartConfig = {
  get markerSize(): Partial<
    Record<
      | PriceTypeEnum
      | components['schemas']['ExternalPriceTypeDTOAttrEnum']
      | components['schemas']['DerivedPriceTypeEnum']
      | components['schemas']['NetbackDerivedPriceEnum']
      | components['schemas']['GasIndexationDerivedPriceTypeEnum']
      | ArbBreakevenPriceType,
      number
    >
  > {
    return {
      spark: 10,
      'spark-assessment': 15,
      'spark-prev': 8,
      'spark-you': 8,
      'spark-percent': 8,
      'spark-min': 6,
      'spark-max': 6,
      'spark-pp-min': 6,
      'spark-pp-max': 6,
      'spark-pp': 8,
      'spark-owner-min': 6,
      'spark-owner-max': 6,
      'spark-owner': 8,
      'deals-avg': 8,
      ttf: 6,
      peg: 6,
      nbp: 6,
      the: 6,
      'ice-ttf': 14,
      'spark-des': 14,
      'spark-mtd': 14,
      'nwe-netback': 10,
      'nea-netback': 10,
    };
  },
  get lineWidth(): Partial<
    Record<
      | PriceTypeEnum
      | components['schemas']['ExternalPriceTypeDTOAttrEnum']
      | components['schemas']['DerivedPriceTypeEnum']
      | components['schemas']['NetbackDerivedPriceEnum']
      | components['schemas']['GasIndexationDerivedPriceTypeEnum']
      | ArbBreakevenPriceType,
      number
    >
  > {
    return {
      spark: 3,
      'spark-assessment': 3,
      'spark-prev': 2,
      'spark-you': 2,
      'spark-percent': 2,
      'spark-min': 1,
      'spark-max': 1,
      'spark-pp-min': 1,
      'spark-pp-max': 1,
      'spark-pp': 2,
      'spark-owner': 2,
      'spark-owner-min': 1,
      'spark-owner-max': 1,
      'deals-avg': 2,
      ttf: 2,
      peg: 2,
      nbp: 2,
      the: 2,
      'ice-ttf': 3,
      'spark-des': 3,
      'spark-mtd': 3,
      'nwe-netback': 3,
      'nea-netback': 3,
      'freight-price': 3,
      'freight-price-breakeven': 3,
      'jkm-ttf-spread': 3,
      'jkm-ttf-spread-breakeven': 3,
    };
  },
  get layers(): Partial<
    Record<
      PriceTypeEnum | components['schemas']['ExternalPriceTypeDTOAttrEnum'],
      number
    >
  > {
    return {
      'spark-max': 1,
      'spark-min': 2,
      spark: 6,
      'spark-you': 8,
      'spark-percent': 8,
      'spark-prev': 8,
      'spark-pp-min': 4,
      'spark-pp-max': 3,
      'spark-pp': 7,
      'spark-owner-min': 14,
      'spark-owner-max': 13,
      'spark-owner': 17,
      'deals-avg': 6,
      ttf: 7,
      peg: 8,
      nbp: 9,
      the: 10,
    };
  },
  plotlyConfig: {
    scrollZoom: false,
    displayModeBar: false,
    responsive: true,
    doubleClick: false,
    doubleClickDelay: 1,
  } as Partial<Config>,
  layoutMargin: {
    l: 20,
    r: 20,
    b: 60,
    t: 20,
    pad: 4,
  },
  QUARTER_FILL_TOP_MODIFIER: 1.04, // % of y1
  QUARTER_FILL_BOTTOM_MODIFIER: 0.94, // % of y0
  QUARTER_LABEL_POSITION_MODIFIER: 1.04,
};

export interface CustomPlotData {
  priceType:
    | PriceTypeEnum
    | components['schemas']['ExternalPriceTypeDTOAttrEnum']
    | components['schemas']['DerivedPriceTypeEnum'];
  subContractType?: SubContractTypeEnum;
  referencePriceType?: boolean;
}

export default (product?: components['schemas']['ProductEnum']) => {
  return {
    getPriceTypeGroup: (priceType: PriceTypeEnum): PriceTypeEnum[] => {
      // All defined dimensions grouped by account view: submitter or not.
      const sparkAll: PriceTypeEnum[] = [
        'spark-min',
        'spark',
        'spark-max',
        'spark-sd',
      ];
      const priceTypes: PriceTypeEnum[] = [];
      switch (priceType) {
        case 'spark-you':
          priceTypes.push(...sparkAll, 'spark-you');
          break;
        case 'spark-prev':
          priceTypes.push(...sparkAll, 'spark-prev');
          break;
        case 'spark-174':
          priceTypes.push(...sparkAll, 'spark-174');
          break;
        case 'spark-160':
          priceTypes.push(...sparkAll, 'spark-160');
          break;
        case 'spark-pp':
          priceTypes.push(
            ...sparkAll,
            'spark-pp-min',
            'spark-pp',
            'spark-pp-max',
          );
          break;
        case 'spark-owner':
          priceTypes.push(
            ...sparkAll,
            'spark-owner-min',
            'spark-owner',
            'spark-owner-max',
          );
          break;
        case 'spark-percent':
          priceTypes.push('spark', 'spark-percent');
          break;

        case 'spark-neutral':
          priceTypes.push(...sparkAll, 'spark-neutral');
          break;
        default:
          priceTypes.push(...sparkAll);
          break;
      }

      return priceTypes?.sort((a, b) => {
        return (
          (PriceChartConfig.layers[a] ?? NaN) -
          (PriceChartConfig.layers[b] ?? NaN)
        );
      });
    },
    getTrace: ({
      name,
      subContractType,
      priceType,
      x = [],
      y = [],
      visible = true,
      unit,
      colorPalette = ['', ''],
      referencePriceType = false,
    }: Partial<SparkPlotData> & {
      subContractType?: SubContractTypeEnum;
      priceType:
        | PriceTypeEnum
        | components['schemas']['ExternalPriceTypeDTOAttrEnum']
        | components['schemas']['DerivedPriceTypeEnum']
        | components['schemas']['NetbackDerivedPriceEnum']
        | components['schemas']['GasIndexationDerivedPriceTypeEnum']
        | ArbBreakevenPriceType;
      unit: UnitEnum;
      colorPalette: string[];
      referencePriceType?: boolean;
    }): Partial<SparkPlotData> => {
      return {
        priceType,
        subContractType,
        referencePriceType,
        x,
        y,
        visible,
        name,
        marker:
          x.length <= 1
            ? {
                size: PriceChartConfig.markerSize[priceType],
                color: colorPalette?.[0],
              }
            : {},
        line: {
          color: colorPalette?.[0],
          shape: 'spline',
          smoothing: 0.6,
          width: PriceChartConfig.lineWidth[priceType],
          simplify: false,
          dash: referencePriceType ? 'dash' : 'solid',
        },
        type: 'scatter',
        mode: x.length <= 1 ? 'markers' : 'lines',
        hoverinfo: 'x',
        hoverlabel: {
          bordercolor: '#BDCCD4',
          font: {
            color: colorPalette?.[1],
          },
        },
        hovertemplate: (y as string[])?.map((v) => {
          const value = getFormattedPrice(v, { unit, product });
          return `<extra></extra><b>${name}</b>: ${value}`;
        }),
      };
    },
    getDeltaTrace: ({
      name = 'Δ',
      subContractType,
      x,
      y,
      unit,
      deliveryPeriods,
      colorPalette = ['#21c08e', '#DB2E20', '#a0aec0'],
    }: {
      name: string;
      subContractType: SubContractTypeEnum;
      x: string[] | number[];
      y: number[];
      unit: UnitEnum;
      deliveryPeriods: {
        type?: components['schemas']['DeliveryPeriodTypeEnum'];
        startAt?: string;
        endAt?: string;
      }[];
      colorPalette: string[];
    }): Partial<SparkPlotData> => {
      const colors = y?.map((v: number) =>
        v > 0 ? colorPalette[0] : v < 0 ? colorPalette[1] : colorPalette[2],
      );
      return {
        subContractType,
        priceType: 'Δ',
        // isReferenceType: this.isReferenceContractDimension(dimension),
        x,
        y,
        showlegend: false,
        name,
        yaxis: 'y2',
        xaxis: 'x2',
        hoverlabel: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore accepts array as well: https://plotly.com/javascript/reference/scatter/#scatter-hoverlabel-bgcolor
          bgcolor: colors,
          bordercolor: '#BDCCD4',
          font: {
            color: y?.map((v: number) => (v < 0 ? '#ffffff' : '#2a3f5f')),
          },
        },
        marker: {
          color: colors,
        },
        type: 'bar',
        textposition: 'none',
        hoverinfo: 'text',
        text: y?.map((val: number, i: number) => {
          const deliveryPeriod = deliveryPeriods?.[i];
          let period = formatDate(deliveryPeriod?.startAt, DateFormat.MMM_YY);
          if (
            deliveryPeriod.type === 'days' ||
            deliveryPeriod.type === 'half-month'
          ) {
            period = formatDate(
              deliveryPeriods?.[i]?.startAt,
              DateFormat.MMM_DD,
              deliveryPeriods?.[i]?.endAt,
            );
            period = `(${period})`;
          } else if (deliveryPeriod.type === 'cal') {
            period = formatDate(deliveryPeriods?.[i]?.startAt, DateFormat.YY);
            period = `Cal\`${period}`;
          }

          const value = getFormattedPrice(val, {
            unit,
            product,
          });
          return `<b>${name} </b> ${period}: ${value}`;
        }),
      };
    },
    getQuarterTrace: ({
      subContractType = 'quarter',
      x,
      y,
      yAxisMax,
      unit,
    }: {
      subContractType?: SubContractTypeEnum;
      x: string[];
      y: number[];
      yAxisMax: number;
      unit: UnitEnum;
    }): Partial<SparkPlotData> => {
      return {
        subContractType,
        derivedTrace: true,
        disableHover: true,
        x,
        y: [yAxisMax],
        label: 'none',
        type: 'scatter',
        mode: 'none',
        hoverinfo: 'x',
        hovertemplate: y?.map((val, i) => {
          return `<extra></extra><b>${formatToQuarter(
            x?.[i],
          )}</b>: ${getFormattedPrice(val, {
            unit,
            withUnit: true,
            product,
          })}`;
        }),
        hoverlabel: {
          bgcolor: 'white',
          font: {
            color: 'black',
          },
        },
      };
    },
    getQuarterlyGroupedDataPoints: (subContract: SubContractDataDTO) => {
      return (
        subContract?.dataPoints
          ?.map((dataPoint) => dataPoint.deliveryPeriod)
          ?.map((period, index) => {
            const periodDayjs = dayjs(period.startAt);
            const month = periodDayjs.get('month');

            if (month % 3 === 0) {
              return {
                quarter: periodDayjs.quarter(),
                date: formatDate(period.startAt, DateFormat.YYYY_MM_DD),
                periodIndex: index,
                index: period.index,
              };
            }
            return undefined;
          })
          ?.filter((p) => p !== undefined) ?? []
      );
    },
  };
};

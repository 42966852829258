import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

export const DATE_FORMAT = {
  MMM_DD: 'MMM DD',
  MMM_YY: 'MMM, YY',
  MMMYY: 'MMM YY',
  DD_MMM_YY: 'DD MMM, YY',
  DAY_AND_DATE: 'ddd, DD MMM, YYYY',
  DDMMMYYYY: 'DD MMM, YYYY',
  MMM: 'MMM',
  YYYY_MM_DD: 'YYYY-MM-DD',
  API_MONTH: 'YYYY-MM',
  TIME: 'HH:mm:ss',
  DAY_AND_TIME: 'DD MMM, YY HH:mm:ss',
  DAY_AND_TIME_AND_TIMEZONE: 'DD MMM YYYY, HH:mm:ss (Z)',
  TIMEZONE: 'Z',
  FROM_NOW: 'fromNow',
  DD: 'DD',
  DD_MMM: 'DD MMM',
  MMMM_YYYY: 'MMMM YYYY',
} as const;

export const formatToApiMonth = (date?: dayjs.Dayjs) => {
  return dayjs(date).format(DATE_FORMAT.API_MONTH);
};

export const formatToApiDate = (date?: dayjs.Dayjs) => {
  return dayjs(date).format(DATE_FORMAT.YYYY_MM_DD);
};

export const formatToQuarter = (date: string) => {
  const dayjsObj = dayjs(date);
  const quarter = dayjsObj.quarter();
  const year = dayjsObj.format('YY');
  const yearSuffix = quarter === 1 ? ` '${year}` : '';
  return `Q${quarter}${yearSuffix}`;
};

export const formatDate = (
  startAt: number | string | Date | Dayjs | undefined | null,
  formatStr: string = DATE_FORMAT.MMM_YY,
  endAt?: number | string | Date | Dayjs,
): string => {
  let formattedDate = '';
  if (endAt && startAt) {
    formattedDate =
      formatStr === 'fromNow'
        ? dayjs(startAt).fromNow()
        : dayjs(startAt).format(formatStr) +
          ' - ' +
          dayjs(endAt).format(formatStr);
  } else if (startAt) {
    formattedDate =
      formatStr === 'fromNow'
        ? dayjs(startAt).fromNow()
        : dayjs(startAt).format(formatStr);
  }
  return formattedDate;
};

export function sortByDate(
  a: string | Date | Dayjs | undefined | null,
  b: string | Date | Dayjs | undefined | null,
  direction: 'asc' | 'desc' = 'asc',
) {
  if (!a && !b) {
    return 0;
  }
  if (!a) {
    return 1;
  }
  if (!b) {
    return -1;
  }
  return direction === 'asc'
    ? dayjs(a).diff(dayjs(b))
    : dayjs(b).diff(dayjs(a));
}

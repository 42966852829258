<script setup lang="ts">
import { onMounted, ref } from 'vue';

const emit = defineEmits<{
  loaded: [src: string];
}>();

const image = ref<HTMLImageElement | null>(null);

function onImageLoad() {
  if (image.value) {
    emit('loaded', image.value.src);
  }
}

function onImageError() {
  console.error('ImageLoader failed to load image');
}

onMounted(() => {
  if (!image.value) return;
  if (image.value.complete) {
    emit('loaded', image.value.src);
  } else {
    image.value.addEventListener('load', onImageLoad);
    image.value.addEventListener('error', onImageError);
  }
});
</script>

<template>
  <img ref="image" />
</template>

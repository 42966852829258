import type { EventBusListener } from '@vueuse/core';
import { useEventBus } from '@vueuse/core';
import { isNil } from 'lodash';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

import { UnitPrecision } from '@/shared/misc/PriceConstantsV2';
import type { components, SpAppProduct, UnitEnum } from '@/types';

type EventName = 'announcement';

export const useAppStore = defineStore('app', () => {
  const appTitle = ref<string>(import.meta.env.VITE_APP_TITLE);
  const SWRegistrationForNewContent = ref<ServiceWorkerRegistration | null>(
    null,
  );
  const refreshingApp = ref<boolean>(false);
  const showAppleAddToHomeMessage = ref<boolean>(false);
  const loadingApp = ref<boolean>(true);
  const loadingContent = ref<boolean>(false);

  const eventBuses = ref<Record<EventName, ReturnType<typeof useEventBus>>>({
    announcement: useEventBus('announcement'),
  });

  const route = useRoute();

  const activeProductId = computed<SpAppProduct | null>(() => {
    if (route.path.match(/^\/freight\/.*/)) {
      return 'lng-freight-platform';
    } else if (route.path.match(/^\/cargo\/.*/)) {
      return 'lng-basis-platform';
    } else if (route.path.match(/^\/access\/.*/)) {
      return 'access-platform';
    } else if (route.path.match(/^\/gas/)) {
      return 'gas-leba-platform';
    } else if (route.path.match(/^\/intraday/)) {
      return 'intraday-platform';
    } else {
      return null;
    }
  });

  const newContentAvailable = computed(
    (): boolean => !isNil(SWRegistrationForNewContent.value),
  );

  const isFreightModule = computed(
    (): boolean => activeProductId.value === 'lng-freight-platform',
  );
  const isBasisModule = computed(
    (): boolean => activeProductId.value === 'lng-basis-platform',
  );
  const isAccessModule = computed(
    (): boolean => activeProductId.value === 'access-platform',
  );
  const getUnitPrecision = computed(() => {
    return (
      unit: UnitEnum = 'usd-per-mmbtu',
      product?: components['schemas']['ProductEnum'],
    ) => {
      const selectedProduct = product ?? activeProductId.value;
      return (selectedProduct && UnitPrecision?.[selectedProduct]?.[unit]) ?? 2;
    };
  });

  function listenEvent(eventName: EventName, callback: EventBusListener) {
    const eventBus = eventBuses.value[eventName];
    if (eventBus) {
      eventBus.on(callback);
    }
  }
  function emitEvent(eventName: EventName, ...args: unknown[]) {
    const eventBus = eventBuses.value[eventName];
    if (eventBus) {
      eventBus.emit(...args);
    }
  }

  return {
    appTitle,
    SWRegistrationForNewContent,
    refreshingApp,
    showAppleAddToHomeMessage,
    loadingApp,
    loadingContent,
    activeProductId,
    eventBuses,
    newContentAvailable,
    isFreightModule,
    isBasisModule,
    isAccessModule,
    getUnitPrecision,
    listenEvent,
    emitEvent,
  };
});

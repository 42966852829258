<script setup lang="ts">
import { CloseOutlined } from '@ant-design/icons-vue';
import * as Sentry from '@sentry/vue';
import { useFavicon } from '@vueuse/core';
import { isArray } from 'lodash';
import { onBeforeMount, onErrorCaptured, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import TheBasisDealsIntegration from '@/core/components/basis-deals/TheBasisDealsIntegration.vue';
import CookieBanner from '@/core/components/CookieBanner.vue';
import MaintenanceMessage from '@/core/components/MaintenanceMessage.vue';
import MandatoryAppUpdate from '@/core/components/MandatoryAppUpdate.vue';
import { useAppStore } from '@/core/stores/app';
import { useAuthStore } from '@/core/stores/auth';
import { useFeatureVersionStore } from '@/core/stores/featureVersion';
import { useMarketingStore } from '@/core/stores/marketing';
import { useMasterDataStore } from '@/core/stores/masterData';
import { LocalStorageManager } from '@/core/utils/localstorage';
import { analyticsTrack } from '@/core/utils/usageAnalytics';
import { AnalyticsEvent } from '@/types';
import { generateRandomString } from '#/utils/generateRandomString';

import AnnouncementHtmlModal from './core/components/announcement-modal/AnnouncementHtmlModal.vue';
import MandatorySetupModal from './core/components/mandatory-setup/MandatorySetupModal.vue';
import Mandatory409Modal from './core/components/Mandatory409Modal.vue';
import RateLimiterMessage from './core/components/RateLimiterMessage.vue';
import { useWsNotifications } from './core/components/websocket/WsNotifications/useWsNotifications';
import ErrorCard from './shared/components/ErrorCard.vue';

const MATOMO_SPARK_EMPLOYEE_DIMENSION_ID = 1;
const devMode = import.meta.env.MODE === 'development';

const route = useRoute();
const router = useRouter();
const masterDataStore = useMasterDataStore();
const appStore = useAppStore();
const authStore = useAuthStore();
const featureVersionStore = useFeatureVersionStore();
const marketingStore = useMarketingStore();

const apiBaseUrl = ref<string | null>(
  import.meta.env.MODE === 'preprod'
    ? import.meta.env.VITE_APP_SP_API_BASE_URL
    : null,
);

if (import.meta.env.MODE !== 'production') {
  useFavicon('/favicon-preprod.ico');
}

if (import.meta.env.VITE_ENABLE_WS_NOTIFICATIONS === 'true') {
  useWsNotifications();
}

const hasError = ref(false);
watch(
  () => authStore.loggedIn,
  (newVal) => {
    //matomo spark staff detection
    if (isArray(window._paq)) {
      window._paq.push([
        'setCustomDimension',
        MATOMO_SPARK_EMPLOYEE_DIMENSION_ID,
        authStore.isSparkUser ? 'yes' : 'no',
      ]);
    }

    if (newVal) {
      // Generate a random ID for Spark support
      window.browsingSessionId = generateRandomString(6);
      // add tools and config for user account
      Sentry.setUser({
        id: authStore.userAccount?.uuid,
        browsingSessionId: window.browsingSessionId,
      });
      Sentry.setTag('browsingSessionId', window.browsingSessionId);

      if (authStore.userAccount) {
        // Initialise Feature Versions
        if (!featureVersionStore.getLocalStorageFeatureVersion())
          featureVersionStore.initialiseFeatureVersionOnLocalStorage();
      }
      // Crisp Live Chat
      if (window.$crisp) {
        window.$crisp.push(['safe', true]);
        window.$crisp.push([
          'set',
          'user:email',
          [authStore.userAccount?.email],
        ]);
        window.$crisp.push(['set', 'session:segments', [['chat']]]);
        window.$crisp.push(['set', 'user:nickname', authStore.displayName]);
        if (authStore.userAccount) {
          window.$crisp.push([
            'set',
            'user:company',
            [
              authStore.userAccount.organisation &&
                authStore.userAccount.organisation.name,
              {
                employment: [
                  authStore.userAccount.jobTitle,
                  authStore.userAccount.jobTitle,
                ],
              },
            ],
          ]);
        }
      }
      appStore.loadingApp = false;
    } else {
      appStore.loadingApp = false;
      // reset config -> track as anonymous user
      Sentry.setUser({
        id: 'unknown',
        browsingSessionId: null,
      });
      Sentry.setTag('browsingSessionId', null);

      if (window.$crisp) {
        window.$crisp.push(['do', 'session:reset']);
      }
    }
  },
);
watch(
  () => appStore.loadingApp,
  (loadingApp) => {
    if (!loadingApp) {
      if (
        authStore.loggedIn &&
        ['login', 'signup', 'signup-1', 'signup-2', 'signup-3'].includes(
          route.name as string,
        )
      ) {
        router.replace('/');
      } else {
        const path =
          location.href.split(location.origin)?.[1] ?? location.pathname;
        router.replace(path);
      }
    }
  },
);
async function listenLogoutEvent() {
  LocalStorageManager.listenLogoutEvent(async () => {
    console.debug('logout event received');
    await authStore.logout({ skipTriggerEvent: true });
    router.push({ name: 'login' });
  });
}

onErrorCaptured((err, vm, info) => {
  hasError.value = true;
  analyticsTrack(AnalyticsEvent.AppErrorCaptured, {
    err,
    vm,
    info,
  });
});

onBeforeMount(async () => {
  await authStore.loadAuthenticatedUser();
  listenLogoutEvent();
  if (authStore.loggedIn) {
    await marketingStore.loadFeedbacks();
  }
});
</script>
<template>
  <div class="h-screen">
    <div
      v-if="apiBaseUrl"
      class="fixed right-0 top-0 z-10 mx-2 flex items-center gap-1 rounded-md bg-gray-200 p-1"
    >
      <a-tag class="mr-0 bg-green-100 text-black">{{ apiBaseUrl }}</a-tag>
      <CloseOutlined
        class="cursor-pointer text-gray-600 hover:text-black"
        @click="apiBaseUrl = null"
      />
    </div>
    <MaintenanceMessage />
    <RateLimiterMessage />
    <!-- Mandatory page takeovers -->
    <Mandatory409Modal />
    <MandatoryAppUpdate v-if="masterDataStore.mandatoryUpdateAvailable" />
    <MandatorySetupModal v-else />
    <!-- end -->

    <div v-if="hasError" class="my-10 flex h-full justify-center">
      <ErrorCard :in-error="hasError" />
    </div>
    <component
      :is="route.meta.layout || 'div'"
      v-else-if="route.meta"
      class="h-full"
    >
      <router-view />
    </component>
    <TheBasisDealsIntegration />
    <AnnouncementHtmlModal />
    <CookieBanner v-if="!devMode" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';

import { analyticsTrack } from '@/core/utils/usageAnalytics';
import { AnalyticsEvent } from '@/types';
import SparkComboBox from '#/components/generic/SparkComboBox/SparkComboBox.vue';
import SparkRadioGroup from '#/components/generic/SparkRadioGroup/SparkRadioGroup.vue';

import CopyButton from './CopyButton.vue';
import IndexationFeedback from './IndexationFeedback.vue';

type IndexationType = 'gas' | 'lng';
type FloatingIndex =
  | 'sparkleba-the-f'
  | 'sparkleba-the-da'
  | 'sparkleba-ttf-f'
  | 'sparkleba-ttf-da';
type UnitType = 'usd-per-mmbtu' | 'eur-per-mwh';
type AveragingMonth = 'preceding' | 'current';
type DifferentialType = 'constant';

const indexationTypeOptions = [
  { name: 'Gas', value: 'gas' },
  { name: 'LNG', value: 'lng' },
];

const floatingIndexOptions = [
  { name: 'SparkLEBA-THE-F', value: 'sparkleba-the-f' },
  { name: 'SparkLEBA-THE-DA', value: 'sparkleba-the-da' },
  { name: 'SparkLEBA-TTF-F', value: 'sparkleba-ttf-f' },
  { name: 'SparkLEBA-TTF-DA', value: 'sparkleba-ttf-da' },
];

const unitTypeOptions = [
  { name: '€/MWh', value: 'eur-per-mwh' },
  { name: '$/MMBtu', value: 'usd-per-mmbtu' },
];

const averagingMonthOptions = [{ name: 'Preceding (101)', value: 'preceding' }];

const differentialTypeOptions = [{ name: 'Constant', value: 'constant' }];
const showFeedbackModal = ref(false);

const inputs = ref<{
  indexationType: IndexationType;
  floatingIndex: FloatingIndex;
  unitType: UnitType;
  averagingMonth: AveragingMonth;
  differentialType: DifferentialType;
  differentialValue: number;
}>({
  indexationType: 'gas',
  floatingIndex: 'sparkleba-the-f',
  unitType: 'eur-per-mwh',
  averagingMonth: 'preceding',
  differentialType: 'constant',
  differentialValue: 0.5,
});

const indexationMessage = computed(() => {
  const indexationTypeText = indexationTypeOptions.find(
    (opt) => opt.value === inputs.value.indexationType,
  )?.name;

  const floatingIndexText = floatingIndexOptions.find(
    (opt) => opt.value === inputs.value.floatingIndex,
  )?.name;

  const unitText = unitTypeOptions.find(
    (opt) => opt.value === inputs.value.unitType,
  )?.name;

  const firstHalf = `The ${indexationTypeText} Contract Price shall be in ${unitText} based upon the average of the ${floatingIndexText} plus ${inputs.value.differentialValue} for each Assessment Day during the full calendar month preceding the month of delivery.`;

  if (inputs.value.unitType === 'eur-per-mwh') {
    return firstHalf;
  }

  const secondHalf = `
  Every settlement price is converted from EUR to USD using the WM/Refinitiv Closing EURUSD Spot Rates as published by Refinitiv at 4 pm UK time on the day the settlement price was published by Spark. When there is no WM/Refinitiv Closing EURUSD Spot Rate published, the next previous WM/Refinitiv Closing EURUSD Spot Rate as published by Refinitiv will be used.`;

  const thirdHalf = `
  Every settlement price is converted from MWh to MMBTU using the conversion rate of 0.29071 MWh to 1 MMBTU.`;

  return firstHalf + '\n' + secondHalf + '\n' + thirdHalf;
});

const copyTextStatus = ref<'' | 'success' | 'fail'>('');

function initEventListeners() {
  window.addEventListener('copy', trackCopyWordings);
}

function removeEventListeners() {
  window.removeEventListener('copy', trackCopyWordings);
}

function trackCopyWordings() {
  const selectionText = document.getSelection()?.toString()?.substring(0, 10);
  const indexationMessageText = indexationMessage.value?.substring(0, 10);
  // first 10chars are matched to avoid an overhead of string formattings cleanup
  if (selectionText === indexationMessageText) {
    analyticsTrack(AnalyticsEvent.SettlementTrackerCopySuggestedWording);
  }
}

onMounted(() => {
  initEventListeners();
});

onUnmounted(() => {
  removeEventListeners();
});
</script>

<template>
  <section class="spark-card-base">
    <div class="flex items-center justify-between gap-6">
      <h4 class="h4">Indexation Builder</h4>
      <SparkTrackerButton
        look="outlined"
        color="gas"
        @click="showFeedbackModal = true"
      >
        Feedback
      </SparkTrackerButton>
    </div>
    <div class="mt-10 flex flex-col gap-1">
      <div class="w-96 space-y-6">
        <div class="line-item">
          <p class="font-medium text-gray-600">Indexation Type</p>
          <SparkRadioGroup
            color="gas"
            v-model="inputs.indexationType"
            :options="indexationTypeOptions"
          />
        </div>
        <div class="line-item">
          <p class="font-medium text-gray-600">Floating Index</p>
          <SparkComboBox
            v-model="inputs.floatingIndex"
            class="w-[150px]"
            color="gas"
            :options="floatingIndexOptions"
            placeholder-text="Floating Index"
          />
        </div>

        <div class="line-item">
          <p class="font-medium text-gray-600">Unit</p>

          <SparkRadioGroup
            color="gas"
            v-model="inputs.unitType"
            :options="unitTypeOptions"
          />
        </div>
        <div class="line-item">
          <p class="font-medium text-gray-600">Averaging Month</p>
          <SparkRadioGroup
            color="gas"
            v-model="inputs.averagingMonth"
            :options="averagingMonthOptions"
          />
        </div>
        <div class="line-item">
          <p class="font-medium text-gray-600">Differential Type</p>
          <SparkRadioGroup
            color="gas"
            v-model="inputs.differentialType"
            :options="differentialTypeOptions"
            title="Differential Type"
          />
        </div>
        <div class="line-item">
          <p class="font-medium text-gray-600">Floating Index</p>
          <a-input-number
            v-model:value="inputs.differentialValue"
            :step="0.1"
            :precision="1"
            style="width: 100px"
          />
        </div>
      </div>
      <a-divider></a-divider>
      <div class="space-y-2">
        <div class="mb-2 flex items-center justify-between gap-2">
          <p class="font-medium text-gray-600">Suggested contract wording:</p>
          <CopyButton
            look="outlined"
            color="gas"
            :disabled="copyTextStatus !== ''"
            :content="indexationMessage"
          />
        </div>
        <div class="spark-card-base prose whitespace-pre-line bg-shade-light">
          {{ indexationMessage }}
        </div>
      </div>
    </div>
    <IndexationFeedback v-model="showFeedbackModal" />
  </section>
</template>

<style scoped>
.line-item {
  @apply grid grid-cols-2 items-center;
}
</style>

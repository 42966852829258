import type {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router';

import { useAuthStore } from '@/core/stores/auth';
import RouteView from '@/shared/components/RouteView.vue';
import AppLayout from '@/shared/layout/AppLayout.vue';
import FullScreenLayout from '@/shared/layout/FullScreenLayout.vue';
import { features, permissions } from '@/shared/misc/constants';

const BasisDiscoverPage = () =>
  import('@/products/basis/pages/discover/DiscoverPage.vue');

const BasisSparkClosePage = () =>
  import('@/products/basis/pages/discover/index.vue');
const BasisSparkCloseSandboxPage = () =>
  import('@/products/basis/pages/spark-close/discover/IndexSandbox.vue');
const BasisSandboxIntradaySubmissionPage = () =>
  import(
    '@/products/basis/pages/spark-close/intraday-submission/BasisSubmissionPage.vue'
  );
const BasisSparkCloseAdminPage = () =>
  import('@/products/basis/pages/spark-close/admin/Index.vue');

const BasisPriceEvolutionPage = () =>
  import('@/products/basis/pages/price-evolution/index.vue');
const BasisSubmissionPage = () =>
  import('@/products/basis/pages/submission/BasisSubmissionPage.vue');

const FFASubmissionPage = () =>
  import('@/products/basis/pages/ffa/submission/FFASubmissionPage.vue');

const FFADiscoverPage = () =>
  import('@/products/basis/pages/ffa/discover/FFADiscoverPage.vue');

const AlertCentrePage = () =>
  import('@/products/basis/pages/alert-centre/AlertCentrePage.vue');

const NetbackPage = () =>
  import('@/products/basis/pages/netback/NetbackPage.vue');

const SettlementTrackerPage = () =>
  import('@/products/basis/pages/settlement-tracker/index.vue');

const NetForwardsPage = () =>
  import('@/products/basis/pages/netforwards/NetForwardsPage.vue');

const NetbackArbBreakevenPage = () =>
  import(
    '@/products/basis/pages/netback-arb-breakeven/NetbackArbBreakevenPage.vue'
  );

const routes: RouteRecordRaw[] = [
  {
    path: '/cargo/submit',
    name: 'basis-submit-contract',
    component: BasisSubmissionPage,
    props: true,
    meta: {
      title: 'Contract Submission',
      product: 'lng-basis-platform',
      requiresAuth: true,
      subroute: true,
      layout: FullScreenLayout,
      hideDeals: true,
    },
  },
  {
    path: '/cargo/ffa/submit/:contractId',
    name: features.BASIS_FFA_SUBMISSION,
    component: FFASubmissionPage,
    meta: {
      title: 'FFA Submission',
      product: 'lng-basis-platform',
      requiresAuth: true,
      layout: FullScreenLayout,
      hideDeals: true,
    },
  },
  {
    path: '/cargo',
    component: RouteView,
    children: [
      {
        path: '',
        redirect: '/cargo/sparknwe',
      },

      // to redirect old route '/basis/discover?contractId=...' to new route
      {
        path: 'discover',
        component: () => '',
        beforeEnter: (to, from, next) => {
          if (to.query.contractId) {
            next(`/cargo/${to.query.contractId}`);
          } else {
            next('/cargo/sparknwe');
          }
        },
      },

      // to redirect old route '/basis/ffa/discover/?contractId=...' to new route
      {
        path: 'ffa/discover',
        component: () => '',
        beforeEnter: (to, from, next) => {
          if (to.query.contractId) {
            next(`/cargo/ffa/${to.query.contractId}`);
          } else {
            next('/cargo/ffa/sparknwe-fin');
          }
        },
      },
      {
        path: ':contractId',
        name: features.BASIS_DISCOVER,
        component: BasisDiscoverPage,
        meta: {
          title: 'Discover',
          product: 'lng-basis-platform',
          requiresAuth: true,
          layout: AppLayout,
          featureIdTemplate: `${features.BASIS_PHYSICAL_PREFIX}{contractId}`,
        },
      },
      {
        path: 'ffa/:contractId',
        name: features.BASIS_FFA_DISCOVER,
        component: FFADiscoverPage,
        meta: {
          title: 'Discover',
          product: 'lng-basis-platform',
          requiresAuth: true,
          layout: AppLayout,
          featureIdTemplate: `${features.BASIS_FFA_PREFIX}{contractId}`,
        },
      },
      {
        path: 'historical-data',
        name: features.BASIS_PRICE_EVOLUTION,
        component: BasisPriceEvolutionPage,
        alias: ['signals', 'signals/price-evolutions/'],
        meta: {
          title: 'Historical Data',
          product: 'lng-basis-platform',
          requiresAuth: true,
          layout: AppLayout,
        },
      },
      {
        path: 'settlement',
        name: features.BASIS_SETTLEMENT_TRACKER,
        component: SettlementTrackerPage,
        meta: {
          title: 'Settlement Tracker',
          product: 'lng-basis-platform',
          requiresAuth: true,
          layout: AppLayout,
          permissions: [{ name: permissions.ACCESS_SETTLEMENT_TRACKER }],
        },
      },
      {
        path: 'alert-centre',
        name: features.BASIS_ALERT_CENTRE,
        component: AlertCentrePage,
        meta: {
          title: 'Alert Centre',
          product: 'lng-basis-platform',
          requiresAuth: true,
          layout: AppLayout,
        },
      },
      {
        path: 'netbacks',
        component: RouteView,
        meta: {
          layout: AppLayout,
        },
        redirect: { name: features.BASIS_NETBACK },
        children: [
          {
            path: '',
            name: features.BASIS_NETBACK,
            component: NetbackPage,
            meta: {
              title: 'Netbacks',
              product: 'lng-basis-platform',
              requiresAuth: true,
              permissions: [
                { prefix: true, name: permissions.PREFIX_ACCESS_NETBACKS },
              ],
              basic: true,
            },
          },
          {
            path: 'arb-breakeven',
            name: features.BASIS_NETBACK_ARB_BREAKEVEN,
            component: NetbackArbBreakevenPage,
            meta: {
              title: 'Arb Breakeven',
              product: 'lng-basis-platform',
              requiresAuth: true,
              permissions: [
                { prefix: true, name: permissions.PREFIX_ACCESS_NETBACKS },
              ],
              basic: true,
            },
          },
        ],
      },
      {
        path: 'netforwards',
        name: features.BASIS_NETFORWARDS,
        component: NetForwardsPage,
        meta: {
          title: 'Netforwards',
          product: 'lng-basis-platform',
          requiresAuth: true,
          permissions: [{ prefix: true, name: permissions.ACCESS_NETFORWARDS }],
          layout: AppLayout,
        },
      },
      {
        path: 'spark-close-sandbox-intro',
        name: features.BASIS_SPARK_CLOSE_SANDBOX_INTRO,
        component: () =>
          import('@/products/basis/pages/spark-close/intro/SandboxIntro.vue'),
        meta: {
          title: 'SparkClose Sandbox Intro',
          product: 'lng-basis-platform',
          requiresAuth: true,
          hideBannerMessage: true,
          layout: AppLayout,
        },
      },
      {
        path: 'spark-close',
        name: features.BASIS_SPARK_CLOSE,
        component: BasisSparkClosePage,
        meta: {
          title: 'Spark Close',
          product: 'lng-basis-platform',
          requiresAuth: true,
          hideBannerMessage: false,
          hideDeals: true,
          layout: AppLayout,
        },
      },
      {
        path: 'spark-close-admin',
        name: features.BASIS_SPARK_CLOSE_ADMIN,
        component: BasisSparkCloseAdminPage,
        meta: {
          title: 'Spark Close Admin',
          product: 'lng-basis-platform',
          permissions: [
            {
              prefix: true,
              name: permissions.PREFIX_CLOSE_ADMIN_ACCESS,
            },
          ],
          requiresAuth: true,
          hideBannerMessage: false,
          hideDeals: true,
          layout: AppLayout,
        },
      },
    ],
  },
  {
    path: '/cargo/sandbox',
    component: () => import('@/products/basis/layout/sandbox.vue'),
    children: [
      {
        path: '',
        name: 'sandbox',
        redirect: 'spark-close',
      },
      {
        path: 'spark-close',
        name: features.BASIS_SPARK_CLOSE_SANDBOX,
        component: BasisSparkCloseSandboxPage,
        meta: {
          title: 'Spark Close',
          product: 'lng-basis-platform',
          permissions: [],
          requiresAuth: true,
          hideBannerMessage: true,
          hideDeals: true,
          layout: AppLayout,
          scrollToTop: true,
        },
        beforeEnter: (
          to: RouteLocationNormalized,
          from: RouteLocationNormalized,
          next: NavigationGuardNext,
        ) => {
          if (
            useAuthStore().hasPermission(
              permissions.ACCESS_SPARK_CLOSE_SANDBOX,
              'lng-basis-platform',
            )
          ) {
            next();
          } else {
            next({ name: features.BASIS_SPARK_CLOSE_SANDBOX_INTRO });
          }
        },
      },
      {
        path: 'submit-intraday',
        name: 'basis-sandbox-intraday-submit',
        component: BasisSandboxIntradaySubmissionPage,
        props: true,
        meta: {
          title: 'Contract Submission',
          product: 'lng-basis-platform',
          permissions: [{ name: permissions.ACCESS_SPARK_CLOSE_SANDBOX }],
          requiresAuth: true,
          subroute: true,
          hideDeals: true,
          layout: FullScreenLayout,
        },
      },
    ],
  },
];

export default routes;

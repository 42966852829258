<script setup lang="ts">
import { debounce, first, flatten, isNil, last } from 'lodash';
import { computed, onMounted, reactive, ref, watch } from 'vue';

import Methodology from '@/core/components/Methodology.vue';
import RedDotTitle from '@/core/components/RedDotTitle.vue';
import { analyticsTrack } from '@/core/utils/usageAnalytics';
import { getFormattedPrice } from '@/products/gas/utils';
import { StaticResourceEnum } from '@/shared/configs/staticResources';
import FilterPageLayout from '@/shared/layout/FilterPageLayout.vue';
import type { components } from '@/types';
import { AnalyticsEvent } from '@/types';
import SparkButtonGroup from '#/components/generic/SparkButtonGroup.vue';
import { SparkRadioGroup } from '#/components/generic/SparkRadioGroup';
import SparkTitledRadioGroup from '#/components/generic/SparkTitledRadioGroup.vue';
import SparkVirtualisedSelect from '#/components/generic/SparkVirtualisedSelect/SparkVirtualisedSelect.vue';
import type { Nullable } from '#/types/core';
import { formatDate } from '#/utils/date';
import { parseNumber } from '#/utils/price';

import { settlementService } from '../../services';
import type { Criteria } from '../types';
import IndexationBuilder from './components/IndexationBuilder.vue';
import SettlementChart from './components/SettlementChart.vue';

const debouncedAnalyticsTrack = debounce(analyticsTrack, 2000);
const methodologyType = StaticResourceEnum.BasisSettlementTrackerMethodology;
const refDataDto = ref<components['schemas']['GasIndexationConfigDTO']>();

const contractOptions = computed(() => refDataDto.value?.contracts);
const unitOptions = computed(() =>
  refDataDto.value?.units.map((u) => ({
    name: u.name,
    value: u.id,

    disabled: u.disabled,
  })),
);
const scenarioVariations = computed(() => {
  return {
    maxGain: refDataDto.value?.scenarioVariations?.maxGain.find(
      (v) => v.unit === criteria.selectedUnit,
    ),
    maxLoss: refDataDto.value?.scenarioVariations?.maxLoss.find(
      (v) => v.unit === criteria.selectedUnit,
    ),
  };
});
const derivedPriceTypeNames = computed(() => {
  const contractName = contractOptions.value?.find(
    (v) => v.value === criteria.selectedContract,
  )?.name;

  const contractType = criteria.selectedSettlementType?.toUpperCase();

  const fullName = `${contractName}-${contractType}`;

  return [
    {
      name: 'SparkNWE-F',
      id: 'spark',
    },

    {
      name: `${fullName} MTD`,
      id: 'spark-mtd',
    },
    {
      name: fullName,
      id: 'spark-assessment',
    },
  ];
});

// Defaults
const criteria = reactive<Criteria>({
  selectedContract: 'sparkleba-ttf',
  selectedSettlementType: 'f',
  selectedContractMonth: undefined,
  maxLossValue: undefined,
  maxGainValue: undefined,
  selectedUnit: 'eur-per-mwh',
});

watch(
  () => criteria.selectedUnit,
  () => {
    if (criteria.selectedUnit === 'eur-per-mwh') {
      criteria.maxGainValue = parseNumber(
        scenarioVariations.value?.maxGain?.value,
      );
      criteria.maxLossValue = parseNumber(
        scenarioVariations.value?.maxLoss?.value,
      );
    } else if (criteria.selectedUnit === 'usd-per-mmbtu') {
      criteria.maxGainValue = parseNumber(
        scenarioVariations.value?.maxGain?.value,
      );
      criteria.maxLossValue = parseNumber(
        scenarioVariations.value?.maxLoss?.value,
      );
    }
  },
);

const results = ref<components['schemas']['GasIndexationDTO'][]>([]);

const loading = ref<boolean>(true);

const settlementTypeOptions = [
  {
    name: 'SparkF',
    value: 'f',
  },
  {
    name: 'SparkDA',
    value: 'da',
  },
];

const contractMonths = computed(() => {
  return results.value?.map((v) => v.contractMonth) || [];
});

const currentMonth = ref<Nullable<string>>(undefined);

const isFrontMonth = computed(() => {
  return currentMonth.value === criteria.selectedContractMonth;
});

const selectedSettlements = computed(() => {
  return results.value?.find(
    (v) => v.contractMonth === criteria.selectedContractMonth,
  );
});

const selectedSettlementTypeName = computed(() => {
  return settlementTypeOptions.find(
    (v) => v.value === criteria.selectedSettlementType,
  )?.name;
});

const selectedContractName = computed(() => {
  const contractName = contractOptions.value?.find(
    (v) => v.value === criteria.selectedContract,
  )?.name;

  return `${contractName}-${criteria.selectedSettlementType?.toUpperCase()}`;
});

const assessmentsTotal = computed(() => {
  return selectedSettlements.value?.assessmentsTotal;
});

const assessmentsCompleted = computed(() => {
  return selectedSettlements.value?.assessmentsCompleted;
});

const assessmentsPending = computed(() => {
  if (!isNil(assessmentsTotal.value) && !isNil(assessmentsCompleted.value)) {
    return assessmentsTotal.value - assessmentsCompleted.value;
  } else {
    return undefined;
  }
});

const monthToDatePriceFormatted = computed(() => {
  if (
    !selectedSettlements.value ||
    isNil(assessmentsCompleted.value) ||
    !criteria.selectedUnit
  ) {
    return null;
  }

  const prices = flatten(
    selectedSettlements.value?.dataPoints
      ?.map((dataPoint) => {
        return dataPoint?.derivedPrices
          ?.find((derivedPrice) => {
            return derivedPrice?.type === 'spark-mtd';
          })
          ?.values?.find((value) => {
            return value?.unit === criteria.selectedUnit;
          })?.value;
      })
      ?.filter((v) => !isNil(v)),
  );

  const price = last(prices);

  return getFormattedPrice(price, {
    unit: criteria.selectedUnit,
    withUnit: true,
  });
});

onMounted(async () => {
  loading.value = true;

  loadSettlementData();

  const response = await settlementService.getSettlementConfig();
  refDataDto.value = response?.data?.data;

  // set defaults
  criteria.selectedContract = refDataDto.value?.selectedContract;
  criteria.selectedUnit = refDataDto.value?.selectedUnit;
  criteria.maxGainValue = parseNumber(scenarioVariations.value?.maxGain?.value);
  criteria.maxLossValue = parseNumber(scenarioVariations.value?.maxLoss?.value);

  loading.value = false;
});

async function loadSettlementData() {
  if (criteria.selectedSettlementType && criteria.selectedContract) {
    const response = await settlementService.getSettlementData(
      criteria.selectedContract,
      criteria.selectedSettlementType,
    );

    results.value = response?.data?.data;

    // auto select latest delivery month if not in contract months
    criteria.selectedContractMonth = first(contractMonths.value);
    currentMonth.value = criteria.selectedContractMonth;
  }
}

function changeDeliveryMonth(value: string) {
  analyticsTrack(AnalyticsEvent.SettlementCriteriaChange, {
    deliveryMonth: value,
  });

  criteria.selectedContractMonth = value;
}

function changeMaxLoss(value: number) {
  criteria.maxLossValue = value;
  debouncedAnalyticsTrack(AnalyticsEvent.SettlementCriteriaChange, {
    maxLoss: value,
  });
}

function changeMaxGain(value: number) {
  criteria.maxGainValue = value;
  debouncedAnalyticsTrack(AnalyticsEvent.SettlementCriteriaChange, {
    maxGain: value,
  });
}

function formatPrice(value?: number) {
  return getFormattedPrice(value, {
    unit: criteria.selectedUnit,
    withUnit: true,
  });
}

function trackContractChanged(selectedContract: string) {
  analyticsTrack(AnalyticsEvent.SettlementCriteriaChange, {
    selectedContract,
  });
}

watch(
  [() => criteria.selectedContract, () => criteria.selectedSettlementType],
  () => {
    loadSettlementData();
  },
);
</script>
<template>
  <FilterPageLayout color="gas">
    <template #page-title>
      <div class="flex items-center justify-center space-x-5 hd:justify-start">
        <RedDotTitle feature-id="gas-settlement-tracker" component-id="all">
          <h3 class="h3">Indexation</h3>
        </RedDotTitle>
        <Methodology :type="methodologyType" render-as="text" color="gas" />
      </div>
    </template>
    <template #filters>
      <a-skeleton
        active
        :loading="!contractOptions?.length"
        :paragraph="{ rows: 2 }"
        :title="false"
      >
        <div class="flex flex-wrap items-center gap-6">
          <SparkTitledRadioGroup
            v-if="contractOptions"
            v-model="criteria.selectedContract"
            title="Contract"
            :options="contractOptions"
            color="gas"
            @update:model-value="trackContractChanged"
          >
          </SparkTitledRadioGroup>

          <SparkButtonGroup title="Contract Type">
            <div class="flex items-center gap-2">
              <SparkRadioGroup
                v-model="criteria.selectedSettlementType"
                :options="settlementTypeOptions"
                color="gas"
              />
              <SparkVirtualisedSelect
                :model-value="criteria.selectedContractMonth"
                :options="
                  contractMonths.map((m) => ({
                    value: m,
                    name: formatDate(m, 'MMM YYYY'),
                  }))
                "
                size="sm"
                placeholder-text="Select"
                class="w-[120px]"
                color="gas"
                @update:model-value="changeDeliveryMonth"
              />
            </div>
          </SparkButtonGroup>

          <SparkTitledRadioGroup
            v-if="unitOptions"
            v-model="criteria.selectedUnit"
            title="Unit"
            :options="unitOptions"
            color="gas"
          >
          </SparkTitledRadioGroup>

          <SparkButtonGroup
            v-if="scenarioVariations.maxGain"
            title="Scenario max gain (/assessment)"
          >
            <div
              class="flex items-center justify-between gap-x-2 rounded-md border border-[#d9d9d9] px-1 transition-colors hover:border-gas-500"
            >
              <a-slider
                :disabled="!isFrontMonth"
                :value="criteria.maxGainValue"
                class="w-[120px]"
                :min="+scenarioVariations.maxGain.min"
                :max="+scenarioVariations.maxGain.max"
                :step="+scenarioVariations.maxGain.step"
                tooltip-placement="bottom"
                @change="changeMaxGain"
              />
              <span class="mr-2 w-[100px] text-right">
                {{ formatPrice(criteria.maxGainValue) }}
              </span>
            </div>
          </SparkButtonGroup>

          <SparkButtonGroup
            v-if="scenarioVariations.maxLoss"
            title="Scenario max loss (/assessment)"
          >
            <div
              class="flex items-center justify-between gap-x-2 rounded-md border border-[#d9d9d9] px-1 transition-colors hover:border-gas-500"
            >
              <a-slider
                :disabled="!isFrontMonth"
                class="red w-[120px]"
                :value="criteria.maxLossValue"
                :min="+scenarioVariations.maxLoss.min"
                :max="+scenarioVariations.maxLoss.max"
                :step="+scenarioVariations.maxLoss.step"
                tooltip-placement="bottom"
                @change="changeMaxLoss"
              />
              <span class="mr-2 w-[110px] text-right">
                {{ formatPrice(criteria.maxLossValue) }}
              </span>
            </div>
          </SparkButtonGroup>
        </div>
      </a-skeleton>
    </template>
    <template #content>
      <div class="flex flex-wrap items-start gap-6">
        <div class="spark-card-base flex-1">
          <div class="flex items-center justify-between gap-4">
            <h4 class="h4">Indexation Chart</h4>
            <a-skeleton
              :loading="
                !criteria.selectedContractMonth ||
                typeof assessmentsTotal === 'undefined' ||
                typeof assessmentsPending === 'undefined'
              "
              active
              :title="{ width: 100 }"
              :paragraph="{ rows: 2, width: 200 }"
            >
              <div
                class="spark-card-base flex flex-col items-end gap-2 border-gas-500 bg-shade-light p-2"
              >
                <div class="flex gap-x-6 gap-y-2">
                  <div>
                    {{ selectedContractName }}
                    {{ assessmentsPending === 0 ? 'Settled Price:' : 'MTD:' }}
                    <span class="whitespace-nowrap font-medium">{{
                      monthToDatePriceFormatted || '-'
                    }}</span>
                  </div>
                </div>
                <div class="flex gap-x-6 gap-y-2">
                  <div>
                    Assessment days in month:
                    <span class="font-medium">{{ assessmentsTotal }}</span>
                  </div>
                  <div>
                    Assessment days remaining:
                    <span class="font-medium">{{ assessmentsPending }}</span>
                  </div>
                </div>
              </div>
            </a-skeleton>
          </div>
          <SettlementChart
            :criteria="criteria"
            :settlement-data="selectedSettlements"
            :settlement-type-name="selectedSettlementTypeName"
            :is-front-month="isFrontMonth"
            :max-allowed-loss="+(scenarioVariations.maxLoss?.max ?? 0)"
            :max-allowed-gain="+(scenarioVariations.maxGain?.max ?? 0)"
            :price-type-names="derivedPriceTypeNames"
          />
        </div>
        <IndexationBuilder class="w-[450px]" />
      </div>
    </template>
  </FilterPageLayout>
</template>
<style lang="scss" scoped>
.ant-slider :deep() {
  margin: 0px 8px;
}
</style>

import { UnitPrecision } from '@/shared/misc/PriceConstantsV2';
import type { components, UnitEnum } from '@/types';
import {
  DEFAULT_UNIT_PRECISION,
  getFormattedPrice as getFormattedPriceUtil,
  parseNumber,
} from '#/utils/price';

export const getFormattedPrice = (
  val?: string | number | null,
  {
    unit,
    withUnit,
    placeholder = '-',
    product,
  }: {
    unit?: UnitEnum | 'usdPerDay' | 'usdPerMMBtu';
    withUnit?: boolean;
    placeholder?: string;
    product?:
      | components['schemas']['ProductEnum']
      | 'lng-freight-platform-routes';
  } = {},
): string => {
  let precision;
  if (product && unit) {
    precision = UnitPrecision[product]?.[unit];
  }
  return getFormattedPriceUtil(val, {
    unit,
    withUnit,
    placeholder,
    precision,
  });
};

export const roundOffPriceValue = (
  val: number,
  unit: UnitEnum | 'usdPerDay' | 'usdPerMMBtu' | 'eurPerMwh',
  precision = DEFAULT_UNIT_PRECISION,
) => {
  let value: number | string = val;

  switch (unit) {
    case 'usdPerDay':
    case 'usd-per-day':
      value = Math.round(value / 250) * 250;
      break;
    case 'eurPerMwh':
    case 'eur-per-mwh':
    case 'usdPerMMBtu':
    case 'usd-per-mmbtu':
      value =
        Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);
      break;
    case 'eur':
    case 'usd':
      value = Math.round(value);
      break;
    default:
      break;
  }
  return value;
};
/**
 * Compute an average and round values.
 * @param {Array} dataPoints
 * @param {unit} unit
 */
export const getAverage = (
  values: (number | string | undefined)[],
  unit: UnitEnum | 'usdPerDay' | 'usdPerMMBtu' | 'eurPerMwh',
  precision?: number,
) => {
  const prices = values
    ?.map((v) => parseNumber(v))
    ?.filter((v: number) => !Number.isNaN(v));
  if (prices.length) {
    const result: number = prices?.reduce((p, c) => p + c, 0) / prices.length;
    return roundOffPriceValue(result, unit, precision);
  }
};

<script setup lang="ts" generic="T = string, TMeta = unknown">
import type { UseVirtualListItem } from '@vueuse/core';
import { useVirtualList } from '@vueuse/core';
import { PopoverContent, PopoverPortal, PopoverRoot } from 'radix-vue';
import {
  computed,
  nextTick,
  onActivated,
  onDeactivated,
  provide,
  ref,
  type UnwrapRef,
  watch,
} from 'vue';

import type { SelectableItem, SparkProductColor } from '#/types/core';

import { SPARK_VIRTUALISED_SELECT_CONTEXT_KEY } from './share';
import SparkVirtualisedSelectItem from './SparkVirtualisedSelectItem.vue';
import SparkVirtualisedSelectTrigger from './SparkVirtualisedSelectTrigger.vue';

const props = withDefaults(
  defineProps<{
    options: SelectableItem<T, TMeta>[];
    modelValue?: T;
    color?: SparkProductColor;
    size?: 'sm' | 'md' | 'lg';
    itemHeight?: number;
    placeholder?: string;
    dropdownWidth?: string;
    triggerAttrs?: Record<string, unknown>;
    dropdownAttrs?: Record<string, unknown>;
    disabled?: boolean;
  }>(),
  {
    modelValue: undefined,
    color: 'green',
    size: 'sm',
    itemHeight: 32,
    placeholder: 'Select...',
    dropdownWidth: 'var(--radix-popper-anchor-width)',
    triggerAttrs: undefined,
    dropdownAttrs: undefined,
    disabled: false,
  },
);

const emit = defineEmits<{
  'update:modelValue': [T];
}>();

defineOptions({
  inheritAttrs: false,
});

defineSlots<{
  trigger?: () => any;
  'trigger-content'?: () => any;
  option?: (props: {
    item: UseVirtualListItem<SelectableItem<T, TMeta>>;
    isHighlighted: boolean;
    isSelected: boolean;
    selectOption: (option: SelectableItem<T, TMeta>) => void;
  }) => any;
}>();

const open = ref(false);
const _modelValue = ref<T | undefined>(props.modelValue);
const highlightIndex = ref<number>(-1);
const mountedItems: Map<number, HTMLElement> = new Map();
const forceMount = ref(true);

const { list, containerProps, wrapperProps, scrollTo } = useVirtualList(
  computed(() => props.options),
  {
    itemHeight: props.itemHeight,
    overscan: 5,
  },
);

const PRODUCT_COLORS: Record<
  SparkProductColor,
  {
    hoverBorderColor?: string;
    glowColor?: string;
  }
> = {
  green: {
    hoverBorderColor: 'var(--tw-green-500)',
    glowColor: '0px 0px 1px var(--tw-green-500)',
  },
  basis: {
    hoverBorderColor: 'var(--tw-basis-500)',
    glowColor: '0px 0px 1px var(--tw-basis-500)',
  },
  access: {
    hoverBorderColor: 'var(--tw-access-500)',
    glowColor: '0px 0px 1px var(--tw-access-500)',
  },
  gas: {
    hoverBorderColor: 'var(--tw-gas-500)',
    glowColor: '0px 0px 1px var(--tw-gas-500)',
  },
  intraday: {
    hoverBorderColor: 'var(--tw-intraday-500)',
    glowColor: '0px 0px 1px var(--tw-intraday-500)',
  },
};

const productColor = computed(() => {
  return PRODUCT_COLORS[props.color];
});

const selectedValue = computed(() => {
  return props.options.find((option) => option.value === props.modelValue)
    ?.value;
});

const selectedOption = computed(() => {
  return (
    props.options.find((option) => option.value === props.modelValue) ??
    props.options.find((option) => option.value === _modelValue.value)
  );
});

function incrementHighlightIndex() {
  highlightIndex.value = Math.min(
    highlightIndex.value + 1,
    props.options.length - 1,
  );
  scrollIntoView(highlightIndex.value);
}

function decrementHighlightIndex() {
  highlightIndex.value = Math.max(highlightIndex.value - 1, 0);
  scrollIntoView(highlightIndex.value);
}

function selectHighlightIndex() {
  if (highlightIndex.value >= 0) {
    selectOption(props.options[highlightIndex.value]);
  }
}

function selectOption(option: SelectableItem<T, TMeta>) {
  _modelValue.value = option.value as UnwrapRef<T>;
  emit('update:modelValue', option.value);
  open.value = false;
}

function scrollIntoView(index: number) {
  const element = mountedItems.get(index);
  if (element) {
    element.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
  } else {
    scrollTo(index);
  }
}

function setItemMounted(index: number, element: HTMLElement) {
  mountedItems.set(index, element);
}

function setItemUnmounted(index: number) {
  mountedItems.delete(index);
}

onActivated(() => {
  forceMount.value = true;
});

onDeactivated(() => {
  forceMount.value = false;
});

watch(open, async (openValue) => {
  if (!openValue) {
    highlightIndex.value = -1;
  } else {
    if (selectedValue.value) {
      const index = props.options.findIndex(
        (option) => option.value === selectedValue.value,
      );
      highlightIndex.value = index;

      // awaits scroller to be rendered
      await nextTick();
      scrollTo(index);
    }
  }
});

provide(SPARK_VIRTUALISED_SELECT_CONTEXT_KEY, {
  open,
  incrementHighlightIndex,
  decrementHighlightIndex,
  selectHighlightIndex,
  setItemMounted,
  setItemUnmounted,
});
</script>

<template>
  <PopoverRoot v-model:open="open">
    <slot name="trigger">
      <SparkVirtualisedSelectTrigger
        v-bind="triggerAttrs"
        :data-size="size"
        :style="{
          '--virtualised-select-trigger-hover-border-color':
            productColor.hoverBorderColor,
          '--virtualised-select-trigger-glow-color': productColor.glowColor,
        }"
        :disabled="disabled"
      >
        <slot name="trigger-content">
          <div v-if="!selectedOption && !modelValue">{{ placeholder }}</div>
          <div v-else-if="!selectedOption && modelValue">{{ modelValue }}</div>
          <div v-else-if="selectedOption">{{ selectedOption.name }}</div>
        </slot>
      </SparkVirtualisedSelectTrigger>
    </slot>

    <PopoverPortal>
      <PopoverContent :side-offset="6" align="start" :force-mount="forceMount">
        <Transition
          class="origin-[--radix-popper-transform-origin] transition-[transform,opacity]"
          enter-from-class="scale-y-75 opacity-0"
          enter-active-class="duration-100"
          enter-to-class="scale-y-100 opacity-100"
          leave-active-class="duration-300"
          leave-to-class="scale-y-75 opacity-0"
        >
          <!-- @pointerdown.prevent is used to prevent the dropdown scroller to steal focus -->
          <div v-if="open" @pointerdown.prevent>
            <div
              v-bind="{ ...containerProps, ...dropdownAttrs }"
              class="max-h-[250px] rounded border border-gray-300 bg-white shadow-lg"
              :style="{ minWidth: dropdownWidth }"
            >
              <div v-bind="wrapperProps">
                <slot
                  v-for="item in list"
                  name="option"
                  :item="item"
                  :is-highlighted="item.index === highlightIndex"
                  :is-selected="item.data.value === selectedValue"
                  :select-option="selectOption"
                >
                  <SparkVirtualisedSelectItem
                    :key="item.index"
                    :style="{ height: `${itemHeight}px` }"
                    :is-highlighted="item.index === highlightIndex"
                    :is-selected="item.data.value === selectedValue"
                    :index="item.index"
                    @pointerdown.prevent
                    @pointermove="highlightIndex = item.index"
                    @click="selectOption(item.data)"
                  >
                    {{ item.data.name }}
                  </SparkVirtualisedSelectItem>
                </slot>
              </div>
            </div>
          </div>
        </Transition>
      </PopoverContent>
    </PopoverPortal>
  </PopoverRoot>
</template>
